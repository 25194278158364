import { useTranslate } from '/machinery/I18n'
import { track } from '/machinery/track'
import { HeadingXs, HeadingLg } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'
import { Image } from '/features/buildingBlocks/Image'
import { Link, LinkUnderlined } from '/features/buildingBlocks/Link'
import { Button } from '/features/buildingBlocks/Button'
import { TextMd } from '/features/buildingBlocks/Text'

import styles from './Contact.css'

import iconLinkedIn from '/images/icons/linkedin.raw.svg'

export function Contact({ title, body, employee }) {
  return (
    <div className={styles.component}>
      <Copy layoutClassName={styles.copy} {...{ title, body }} />
      {employee && <Employee layoutClassName={styles.employee} {...employee} />}
    </div>
  )
}

function Copy({ title, body, layoutClassName }) {
  return (
    <div className={cx(styles.componentCopy, layoutClassName)}>
      <HeadingLg h={2}>{title}</HeadingLg>
      <TextMd layoutClassName={styles.body}>{body}</TextMd>
    </div>
  )
}

function Employee({ firstName, lastName, image, jobTitle, phone, mobile, email, linkedIn, hubspot, layoutClassName }) {
  const name = `${firstName} ${lastName}`
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentEmployee, layoutClassName)}>
      <Avatar
        layoutClassName={styles.avatar}
        onClick={() => trackClick({ type: 'linkedin' })}
        {...{ image, linkedIn }}
      />
      <div className={styles.details}>
        <HeadingXs layoutClassName={styles.name}>
          <span className={styles.breakWord}>{name}</span>
        </HeadingXs>
        <p className={styles.jobTitleAndLinks}>
          <span className={styles.breakWord}>{jobTitle}</span>
          {phone && (
            <LinkUnderlined
              href={`tel:${phone.replace(/\s+/g, '')}`}
              dataX='contact-phone'
              onClick={() => trackClick({ type: 'phone' })}
            >
              {phone}
            </LinkUnderlined>
          )}
          {mobile && (
            <LinkUnderlined
              href={`tel:${mobile.replace(/\s+/g, '')}`}
              dataX='contact-phone'
              onClick={() => trackClick({ type: 'phone' })}
            >
              {mobile}
            </LinkUnderlined>
          )}
          {email && (
            <LinkUnderlined
              href={`mailto:${email}`}
              dataX='contact-mail'
              onClick={() => trackClick({ type: 'email' })}
            >
              {email}
            </LinkUnderlined>
          )}
        </p>
        {hubspot && (
          <p className={styles.hubspot}>
            <Button
              dataX='hubspot'
              href={hubspot}
              >
              {__`schedule-a-meeting`}
            </Button>
          </p>
        )}
      </div>
    </div>
  )

  function trackClick({ type }) {
    return (
      track({
        event: 'click',
        metadata: {
          contact: {
            name: firstName,
            type,
          }
        }
      })
    )
  }
}

function Avatar({ image, linkedIn, layoutClassName, onClick }) {
  return (
    <div className={cx(styles.componentAvatar, layoutClassName)}>
      {image?.asset && <Image layoutClassName={styles.image} imgProps={{ loading: 'lazy' }} {...{ image }} />}
      {linkedIn && (
        <Link
          href={linkedIn}
          target='_blank'
          layoutClassName={styles.icon}
          dataX='contact-linkedin'
          {...{ onClick }}
        >
          <Icon icon={iconLinkedIn} label='LinkedIn' />
        </Link>
      )}
    </div>
  )
}
